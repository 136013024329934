import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import cn from "classnames";

import "@mikker/btn";
import "tachyons";
import "./layout.css";

const Layout = ({ title, className, children }) => (
  <main className={cn("sans-serif black", className)}>
    <Helmet
      title={title || "RAGE AGAINST THE MAINSTREAM - DOWNLOAD"}
      meta={[
        { name: "description", content: "Sample" },
        { name: "keywords", content: "sample, something" }
      ]}
    />

    {children}
  </main>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
